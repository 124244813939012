import { Text } from '@cinch-labs/design-system'
import { AppPageBody } from 'libs/data/storyblok/src/lib/types/shared'
import Image from 'next/image'
import styles from './qr-section.module.css'
import { useViewport } from '@cinch-labs/shared-util'
import { TickIcon } from '@cinch-nx/design-system-icons'

interface QrCodeSectionProps {
  sectionData: AppPageBody
}

export const QrCodeSection = ({ sectionData }: QrCodeSectionProps) => {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isDesktop = vw > 767

  const { title, qr_code, disclaimer, features_list } = sectionData

  return (
    <section className={styles.wrapper}>
      {isDesktop && (
        <div className={styles.qrWrapper}>
          <Text element="h2" fontSize="3xl" className={styles.title}>
            {title}
          </Text>

          {qr_code && (
            <div aria-label={qr_code.alt ?? ''} className={styles.qrImage}>
              <Image
                alt={qr_code.alt ?? ''}
                src={qr_code.filename}
                height={320}
                width={320}
                layout="responsive"
              />
            </div>
          )}
          <Text className={styles.disclaimer}>{disclaimer}</Text>
        </div>
      )}
      <div className={styles.listWrapper}>
        <ul className={styles.featureList} aria-label="mobile app feature list">
          {features_list &&
            features_list
              .filter((item) => item && item.feature)
              .map((item) => (
                <li
                  key={item._uid}
                  aria-label={item.feature}
                  className={styles.featureItem}
                >
                  <div className={styles.featureIcon}>
                    <div className={styles.icon}>
                      <TickIcon size={16} />
                    </div>
                  </div>

                  <Text className={styles.featureText}>{item.feature}</Text>
                </li>
              ))}
        </ul>
      </div>
    </section>
  )
}
