import { IconList } from '@cinch-nx/data-storyblok'
import {
  CashIcon,
  FilterIcon,
  HomeDeliveryIcon,
  MakeModelIcon,
} from '@cinch-nx/design-system-icons'

import styles from './why-app.module.css'

export interface WhyAppInformationProps {
  heading: string
  listOfItems: IconList[]
  ariaLabelForList: string
  disclaimer: string
}

interface ComponentMapType {
  [key: string]: JSX.Element
}

const ComponentMap: ComponentMapType = {
  cash: <CashIcon size={24} />,
  filter: <FilterIcon size={24} />,
  'home-delivery': <HomeDeliveryIcon size={24} />,
  'vehicle-front': <MakeModelIcon size={24} />,
} as const

export const WhyAppInformation = ({
  heading,
  listOfItems,
  ariaLabelForList,
  disclaimer,
}: WhyAppInformationProps) => (
  <section className={styles.whyAppInformationContainer}>
    <h1 className={styles.whyAppInformationSectionHeading}>{heading}</h1>
    <ul
      aria-label={ariaLabelForList}
      className={styles.whyAppInformationSectionList}
    >
      {listOfItems.map((item: IconList) => {
        const iconName = item.icon

        return (
          <li
            key={item.text}
            aria-label={item.text}
            className={styles.whyAppInformationListItem}
          >
            <div className={styles.whyAppInformationIconCard}>
              {ComponentMap[iconName]}
            </div>
            <div className={styles.whyAppInformationIconCardText}>
              {item.text}
            </div>
          </li>
        )
      })}
    </ul>
    <p className={styles.whyAppInformationDisclaimer}>{disclaimer}</p>
  </section>
)
