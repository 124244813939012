import { SvgIcon } from '@cinch-labs/design-system'
import type {
  GradientNames,
  IconNames,
} from '@cinch-labs/design-system/src/tokens/tokens.types'
import classnames from 'classnames'
import React, { ForwardRefRenderFunction } from 'react'

import { AccordionIdNames } from './accordion.component'
import styles from './accordion.module.css'

interface AccordionButtonProps extends AccordionIdNames {
  id: number
  isExpanded?: boolean
  toggleExpanded: () => void
  setFocusActive: (index?: number) => void
  buttonText: string | React.ReactNode | React.ReactNode[]
  totalItems: number
  iconType?: keyof typeof IconNames
  iconGradient?: keyof typeof GradientNames
}

interface KeyboardEventsManagerProps {
  [key: string]: () => number
}

const handleArrowDown = (accordionIndex: number, totalItems: number) => {
  const nextAccordionIndex = accordionIndex + 1

  if (accordionIndex === totalItems) {
    return 1
  }

  return nextAccordionIndex
}

const handleArrowUp = (accordionIndex: number, totalItems: number) => {
  const nextAccordionIndex = accordionIndex - 1

  if (accordionIndex === 1) {
    return totalItems
  }

  return nextAccordionIndex
}

const getOpenAccordionKeyCode = (id: number): KeyboardEventsManagerProps => ({
  '13': () => id,
  '32': () => id,
})

const getKeyCodes = (
  id: number,
  totalItems: number,
): KeyboardEventsManagerProps => ({
  '40': () => handleArrowDown(id, totalItems),
  '38': () => handleArrowUp(id, totalItems),
  '36': () => 1,
  '35': () => totalItems,
})

const AccordionButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  AccordionButtonProps
> = (
  {
    isExpanded,
    id,
    toggleExpanded,
    buttonText,
    totalItems,
    setFocusActive,
    buttonId,
    sectionId,
    iconType,
    iconGradient,
  },
  ref,
) => {
  const iconGradientFill = iconGradient ? iconGradient : undefined
  const iconColorFill = !iconGradient ? 'deepPurple' : undefined

  const keyboardEventHandler = (event: React.KeyboardEvent) => {
    const keyCodes = getKeyCodes(id, totalItems)
    const openAccordionKeyCode = getOpenAccordionKeyCode(id)

    if (event.keyCode in openAccordionKeyCode) {
      event.preventDefault()

      toggleExpanded()
      return
    }

    if (event.keyCode in keyCodes) {
      event.preventDefault()

      setFocusActive(keyCodes[event.keyCode]())
      return
    }
  }

  return (
    <h3 className={styles.accordionTitle}>
      <button
        type="button"
        aria-expanded={isExpanded}
        aria-disabled={isExpanded}
        aria-controls={`${sectionId}-${id}`}
        id={`${buttonId}-${id}`}
        data-testid={`${buttonId}-${id}`}
        onClick={toggleExpanded}
        onKeyDown={keyboardEventHandler}
        ref={ref}
        className={styles.accordionButton}
      >
        {iconType ? (
          <SvgIcon
            name={iconType}
            className={styles.icon}
            color={iconColorFill}
            size={24}
            gradient={iconGradientFill}
          />
        ) : null}

        {buttonText}
        <SvgIcon
          name="chevron-down"
          className={classnames(styles.chevron, {
            [styles.chevronRotate]: isExpanded,
          })}
          color="deepPurple"
          size={16}
        />
      </button>
    </h3>
  )
}

export default React.forwardRef(AccordionButton)
