import { MobileAppProps } from './get-static-props'
import { QrCodeSection } from './components/qr-section/qr-section'
import { Wrapper } from '@cinch-nx/shared-ui'
import styles from './landing-mobile-app.module.css'
import { WhyAppInformation } from './components/why-app/why-app'
import { IconList } from '@cinch-nx/data-storyblok'
import Head from '@cinch-nx/landing-ui-shared/lib/head/head'
import { useStoryblokState } from '@cinch-nx/data-storyblok'

export const LandingMobileApp = ({
  story,
  generatedAt,
  preview,
}: MobileAppProps) => {
  const { body } = story.content

  const qrSectionData = body.find((qrdata) => qrdata.component === 'QR Section')
  const whyAppSectionData = body.find(
    (whyAppData) => whyAppData.component === 'Why Section',
  )

  const hasQrSection = qrSectionData
  const hasWhyAppSection = whyAppSectionData

  const { content } = useStoryblokState({ story, enableBridge: preview })
  const [SEO] = content?.SEO ?? [{}]

  return (
    <>
      <Head
        {...SEO}
        generatedAt={generatedAt}
        fallbackTitle="App - cinch"
        fallbackDescription="We're the faff-free car service that puts you in the driving seat. We help you find and buy used cars – quickly and easily. Buy online with free home delivery or from an approved dealer."
      />
      <Wrapper isSlim className={{ container: styles.qrWrapper }}>
        {hasQrSection && <QrCodeSection sectionData={qrSectionData} />}
      </Wrapper>

      <Wrapper isSlim backgroundColor="#F2F5FF">
        {hasWhyAppSection && (
          <WhyAppInformation
            heading={whyAppSectionData.title}
            listOfItems={whyAppSectionData.icon_list as IconList[]}
            ariaLabelForList={'why app information section'}
            disclaimer={whyAppSectionData.disclaimer}
          />
        )}
      </Wrapper>
    </>
  )
}
