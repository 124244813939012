import cx from 'classnames'
import Script from 'next/script'
import { FC, useEffect, useRef } from 'react'

import { TrustpilotWidgetProps } from './types'

export enum TrustpilotWidgetTemplates {
  carousel = '54ad5defc6454f065c28af8b',
  strip = '5613c9cde69ddc09340c6beb',
  starter = '5613c9cde69ddc09340c6beb',
}

export const TrustpilotWidget: FC<TrustpilotWidgetProps> = ({
  widget = 'carousel',
  className,
  height = '240px',
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot && ref?.current) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <>
      <Script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      <div
        ref={ref}
        className={cx('trustpilot-widget', className)}
        data-locale="en-GB"
        data-template-id={TrustpilotWidgetTemplates[widget]}
        data-businessunit-id="5d513cfbed9f5f00012db9a5"
        data-style-height={height}
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-testid="trust-pilot"
      >
        <a
          href="https://uk.trustpilot.com/review/cinch.co.uk"
          target="_blank"
          rel="noreferrer noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}

export default TrustpilotWidget
