import { AppPageStoryContent } from '../types/mobile-app'
import { getStoryBySlug } from '@cinch-nx/data-storyblok'
import { InferGetStaticPropsType } from 'next'

export const getStaticProps = async ({ preview = false }) => {
  const story = await getStoryBySlug<AppPageStoryContent>('app', {
    version: preview ? 'draft' : 'published',
  })

  const HeroProps = story?.content?.hero?.[0]

  return {
    props: {
      story,
      preview,
      generatedAt: Date.now(),
      layoutProps: {
        heroProps: HeroProps,
        showBorderRadius: false,
      },
    },
    revalidate: 300, // In seconds
  }
}

export type MobileAppProps = InferGetStaticPropsType<typeof getStaticProps>
